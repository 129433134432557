import { useContext, useState, useEffect } from "react"
import Modulos from "../../../general/screen/Modulos"
import useTitle from "../../../hooks/useTitle"
import HomeInUser from "../components/HomeInUser"
import HomeAgendamientosDetalle from "../components/HomeAgendamientosDetalle"
import AuthContext from "../../../../context/auth/authContext"
import { getSchedules, getSchedulesCow } from "../../../../services/agenda"
import { getToken } from "../../../helpers/validations"
import { useNavigate } from "react-router"

const HomeAgendamientos = () => {

    useTitle({ title: "Mis Agendamientos" })

    const {globalDataUser, globalEcosistemas, globalDetalleEcosistemas, globalModulos, globalChangeVideoLlamadaStatus, globalEnLlamada, GLOBAL} = useContext(AuthContext)

    const [agendas, setAgendas] = useState([])
    const [idPrimerModuloActivoUser, setIdPrimerModuloActivoUser] = useState(2)

    let navigate = useNavigate()

    const gotoBeneficios = () => {
        navigate('/')
    }

    const getAgendasUsuario = () => {
        let array = []
        const detalleEcosistemas = globalDetalleEcosistemas.filter((e) => e.esAgendamiento === true)
        detalleEcosistemas.forEach((ed) => {
            if (ed.title !== 'Coworking') {
                getSchedules(getToken(), '0', ed.agendaTipo).then((resp) => {
                    if (resp.codigo === 0) {
                        let data = resp.data.$values[0]
                        let objAgenda = {
                            servicio: ed.title,
                            idServicio: ed.detail_eco,
                            idAgenda: data.idAgenda,
                            fechaAgenda: new Date(data.fechaAgenda),
                            hora: data.horaAgenda,
                            medio: data.tipoLlamada,
                            telefono: data.contacto,
                            urlEdit: ed.linkEditAgendamiento,
                            urlVideollamada: data.salaCliente
                        }
                        array.push(objAgenda)
                        setAgendas([...array])
                    }
                })
            } else {
                getSchedulesCow(getToken(), globalDataUser.globalUsuId).then((resp) => {
                    if (resp.$values.length !== 0) {
                        let data = resp.$values[0]
                        let objAgenda = {
                            servicio: ed.title,
                            fechaAgenda: new Date(data.fechaAgendamiento),
                            hora: data.horario,
                            medio: data.tipoLlamada,
                            telefono: data.contacto,
                            urlEdit: ed.linkEditAgendamiento,
                            urlVideollamada: data.salaCliente
                        }
                        array.push(objAgenda)
                        setAgendas([...array])
                    }
                })
            }           
        })
    }

    useEffect(() => {
        let moduloSeteado = false
        Object.entries(globalModulos).forEach(([nombre, value]) => {
            if(value && !moduloSeteado) {
                let modulos = globalEcosistemas.filter(e => e.modulo === nombre && e.active === true)
                setIdPrimerModuloActivoUser(modulos[0].id)
                moduloSeteado = true   
            }
        })
        getAgendasUsuario()
    }, [])



    return (
        <>
            <div className="homein">
                <div className='desktop proteccion__titulo rojo'></div>
                <div className="homein__resumen d-none d-lg-block">
                    <HomeInUser id={idPrimerModuloActivoUser}/>
                </div>
                
                <div className="homein__detalle d-lg-block d-none">
                    <Modulos />
                    <div className="eco__sombreado">
                        <h1 style={{ fontFamily: "Scotia-Headline", color: 'var(--rojo)', paddingBottom: '3rem', marginTop: '3rem' }}>Mis agendamientos</h1>
                        {
                            agendas?.length === 0 ?
                            <div className="div__sin__agendas">
                                <h2>Aún no tienes citas agendadas</h2>
                                <p>Recuerda que aprovechar todos los beneficios de tu seguro es rápido, fácil y no tiene costo</p>
                                <p>Agenda una cita ahora</p>

                                <div className="btn__ver__beneficios">
                                    <button style={{ color: '#fff', backgroundColor: 'var(--rojo)', borderColor: 'var(--rojo)', width: '16rem' }} onClick={gotoBeneficios}>Ver mis beneficios</button>
                                </div>
                            </div>
                            :
                            <div className="container text-center" style={{ paddingTop: '5rem' }}>
                                <div className="row row-cols-xxl-3 row-cols-xl-2 row-cols-1 justify-content-center" style={{ rowGap: '10rem' }}>
                                    {
                                        agendas.map((a, index) => {
                                            return (
                                                <div className="col agenda__detalle">
                                                    <HomeAgendamientosDetalle agenda={a} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="d-lg-none d-block">
                    <div className="eco__sombreado">
                        <div style={{ backgroundColor: 'var(--rojo)', paddingBottom: '14rem', position: 'relative' }}>
                            <h1 style={{ fontFamily: "Scotia-Headline", color: '#ffffff', marginBottom: '2rem', fontSize: '3rem', position: 'absolute', bottom: '0', width: '100%' }}>Mis agendamientos</h1>
                        </div>
                        <div className="homein__detalle">
                            {
                                agendas?.length === 0 ?
                                <div className="div__sin__agendas">
                                    <h2>Aún no tienes citas agendadas</h2>
                                    <p>Recuerda que aprovechar todos los beneficios de tu seguro es rápido, fácil y no tiene costo</p>
                                    <p>Agenda una cita ahora</p>

                                    <div className="btn__ver__beneficios">
                                        <button style={{ color: '#fff', backgroundColor: 'var(--rojo)', borderColor: 'var(--rojo)', width: '16rem' }} onClick={gotoBeneficios}>Ver mis beneficios</button>
                                    </div>
                                </div>
                                :
                                <div className="container text-center" style={{ paddingTop: '5rem' }}>
                                    <div className="row row-cols-xxl-3 row-cols-xl-2 row-cols-1 justify-content-center" style={{ rowGap: '10rem' }}>
                                        {
                                            agendas.map((a, index) => {
                                                return (
                                                    <div className="col agenda__detalle">
                                                        <HomeAgendamientosDetalle agenda={a} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeAgendamientos